/* eslint-disable */
import {
  FETCH_ALL_AGENT_DETAILS,
  FETCH_ALL_AGENT_DETAILS_SUCCESS,
  UPDATE_AGENT_DETAILS,
  INITIAL_AGENT_DETAILS,
  FETCH_AGENT_DETAILS,
  ADD_AGENT_DETAILS_SUCCESS,
  UPDATE_AGENT_DATA_SUCCESS,
  DELETE_AGENT_DETAILS_SUCCESS,
  AGENT_DETAILS_FAILURE,
  UPDATE_INITIAL_TAGS_DATA,
  FETCH_ALL_TAGS_DETAILS,
  FETCH_ALL_TAGS_DETAILS_SUCCESS,
  TAG_DETAILS_FAILURE,
  FETCH_TAG_DETAILS,
  UPDATE_TAG_DETAILS,
  INITIAL_TAG_DETAILS,
  ADD_TAG_DETAILS_SUCCESS,
  UPDATE_TAG_DATA_SUCCESS,
  DELETE_TAG_DETAILS_SUCCESS,
  FETCH_ALL_TEMPLATE_MESSAGE_DETAILS,
  FETCH_ALL_TEMPLATE_MESSAGE_DETAILS_SUCCESS,
  TEMPLATE_MESSAGE_DETAILS_FAILURE,
  FETCH_TEMPLATE_MESSAGE_DETAILS,
  UPDATE_TEMPLATE_MESSAGE_DETAILS,
  INITIAL_TEMPLATE_MESSAGE_DETAILS,
  INITIAL_CANNED_MESSAGE_DETAILS,
  UPDATE_CANNED_MESSAGE_DETAILS,
  FETCH_CANNED_MESSAGE_DETAILS,
  CANNED_MESSAGE_DETAILS_FAILURE,
  FETCH_ALL_CANNED_MESSAGE_DETAILS_SUCCESS,
  FETCH_ALL_CANNED_MESSAGE_DETAILS,
  ADD_CANNED_MESSAGE_DETAILS_SUCCESS,
  DELETE_CANNED_MESSAGE_DETAILS_SUCCESS,
  UPDATE_CANNED_MESSAGE_DATA_SUCCESS,
  DELETE_TEMPLATE_MESSAGE_DETAILS_SUCCESS,
  UPDATE_TEMPLATE_MESSAGE_DATA_SUCCESS,
  ADD_TEMPLATE_MESSAGE_DETAILS_SUCCESS,
  UPDATE_INITIAL_USER_ATTRIBUTE_DATA,
  FETCH_ALL_USER_ATTRIBUTE_DETAILS,
  FETCH_ALL_USER_ATTRIBUTE_DETAILS_SUCCESS,
  USER_ATTRIBUTE_DETAILS_FAILURE,
  FETCH_USER_ATTRIBUTE_DETAILS,
  UPDATE_USER_ATTRIBUTE_DETAILS,
  INITIAL_USER_ATTRIBUTE_DETAILS,
  ADD_USER_ATTRIBUTE_DETAILS_SUCCESS,
  UPDATE_USER_ATTRIBUTE_DATA_SUCCESS,
  DELETE_USER_ATTRIBUTE_DETAILS_SUCCESS,
  // FETCH_UPDATE_AGENT_SUCCESS,
  // FETCH_UPDATE_AGENT_DETAIL,
  // FETCH_UPDATE_AGENT_FAILED,
  // UPDATE_AGENT_PROFILE_DETAIL,
  // UPDATE_AGENT_PROFILE_SUCCESS,
  // UPDATE_AGENT_PROFILE_FAILURE,
  BILLING_DATE_RANGE_STATUS,
  BILLING_DATE_RANGE,
  BILLING_CREDIT_AMOUNT_STATUS,
  BILLING_CREDIT_AMOUNT,
  BILLING_PAYMANT_PLANS_LIST_STATUS,
  BILLING_PAYMANT_PLANS_LIST,
  BILLING_USER_COUNT_STATUS,
  BILLING_USER_COUNT_STATS,
  BILLING_NOTIFICATION_COUNT_STATUS,
  BILLING_NOTIFICATION_COUNT,
  FETCH_NEW_BUSINESS_STATUS,
  FETCH_NEW_BUSINESS,
  ANALYTICS_BODY_DETAILS,
  ANALYTICS_AGENT_PERFORMANCE_DETAILS,
  FETCH_ANALYTICS_AGENT_PERFORMANCE_STATS,
  FETCH_ANALYTICS_AGENT_PERFORMANCE_STATS_FAILURE,
  FETCH_ANALYTICS_AGENT_PERFORMANCE_STATS_SUCCESS,
  FETCH_ANALYTICS_OVERVIEW_STATS,
  FETCH_ANALYTICS_OVERVIEW_STATS_FAILURE,
  FETCH_ANALYTICS_OVERVIEW_STATS_SUCCESS,
  LIST_BILLING_PAYMENT_METHOD_SUCCESS,
  LIST_BILLING_PAYMENT_METHOD_FAILURE,
  ADD_BILLING_PAYMENT_METHOD_SUCCESS,
  ADD_BILLING_PAYMENT_METHOD_FAILURE,
  LIST_BILLING_ADDRESS_SUCCESS,
  LIST_BILLING_ADDRESS_FAILURE,
  ADD_NEW_BILLING_ADDRESS_FAILURE,
  ADD_NEW_BILLING_ADDRESS_SUCCESS,
  NEW_BILLING_ADDRESS_UPDATE_SUCCESS,
  NEW_BILLING_ADDRESS_UPDATE_FAILURE,
  BILLING_PLAN_LIST_FAILURE,
  BILLING_PLAN_LIST_SUCCESS,
  UPDATE_INITIAL_TEMPLATE_DATA,
  UPDATE_INITIAL_CANNED_DATA,
  UPDATE_INITIAL_ALL_AGENT_DATA,
  LIST_PUSH_DEVICE_SUCCESS,
  ADD_PUSH_DEVICE_SUCCESS,
  UPDATE_PUSH_DEVICE_SUCCESS,
  DETAILS_PUSH_DEVICE_STATUS,
  DELETE_PUSH_DEVICE_SUCCESS,
  UPDATE_PUSH_DEVICE_SOUND,
  ACTIVE_SUBSCRIPTION_LIST_SUCCESS,
  ACTIVE_SUBSCRIPTION_LIST_DETAILS,
  UPDATE_BUTTON_CLICKED,
  SYNC_TEMPLATE_MESSAGE,
  SYNC_TEMPLATE_MESSAGE_SUCCESS,
  SYNC_TEMPLATE_MESSAGE_FAILURE,
  ADD_TEMPLATE_MESSAGE_DETAILS,
  ADD_TEMPLATE_MESSAGE_DETAILS_FAILURE,
  UPDATE_BUSINESS_DETAILS,
  UPDATE_BUSINESS_DETAILS_EMPTY,
  UPDATE_BUSINESS_DATA,
  FETCHING_WORKING_HOURS_FROM_LIVE_CHAT_SETTINGS,
  FETCH_WORKING_HOURS_FROM_LIVE_CHAT_SETTINGS,
  UPDTAE_LIVE_CHAT_SETTINGS_CONFIG,
  UPDTAING_LIVE_CHAT_SETTINGS_CONFIG,
  FETCHING_LIVE_CHAT_SETTINGS,
  UPDATE_LIVE_CHAT_SETTINGS_AUTO_REPLY,
  FETCH_ALL_TAG_CATEGORIES,
  ADD_NEW_TAG_CATEGORY,
  FETCH_PREDIFINED_TEMPLATES,
  UPDATE_TEMPLATE_MESSAGE,
  FETCH_BILLING_STATS_STATUS,
  FETCH_BILLING_STATS,
  UPDATE_IS_OPTED_OUT_AUTOMATE_REPLY,
  OPT_OUT_KEYWORDS,
  UPDATE_OPT_OUT_CONFIG_DATA,
  UPDATING_OPT_OUT_CONFIG_DATA,
  UPDATE_OPT_IN_CONFIG_DATA,
  OPT_IN_KEYWORDS,
  GET_QUALITY_RATING,
  UPDATE_BUSINESS_ADMIN_DETAILS,
  FETCH_CONVERSATION_USAGE,
  FETCH_CONVERSATION_CREDIT_USAGE,
  FETCH_FB_ACCOUNT_DATA,
  FETCH_FB_PAGES_ACCOUNT_DATA,
  UPDATE_FB_CAMPAIGN_DATA,
  FETCH_FB_AD_ACCOUNT_FORM_META,
  CLEAR_FB_CAMPAIGN_DATA,
  UPDATE_REGION_DATA,
  FETCH_FB_CAMPAIGN_LIST,
  CLEAR_REGION_DATA,
  UPDATE_INITIAL_FB_CAMPAIGN_LIST,
  FETCH_FB_CAMPAIGN_STATUS,
  FETCH_FB_CAMPAIGN_STATUS_BY_DATE,
  UPDATE_SELECTED_CAMPAIGN,
  UPDATE_META_DETAILS,
} from "modules/Manage/ManagePageActionTypes";
import _ from "lodash";
import moment from "moment";
import { DEFAULT_PAGINATION_INFO } from "constants/const";

const initialState = {
  teamSetting: {
    pageNum: DEFAULT_PAGINATION_INFO.pageNum,
    pageSize: DEFAULT_PAGINATION_INFO.pageSize,
    agent: {
      contact_name: "",
      contact_email: "",
      country_code: "+91",
      phone_number: "",
      username: "",
      local_password: "",
      role: "",
      status: "",
      project_id: "",
      business_id: "",
    },
    fetching: false,
    agentCount: 0,
    agents: [],
  },
  tags: {
    pageNum: DEFAULT_PAGINATION_INFO.pageNum,
    pageSize: DEFAULT_PAGINATION_INFO.pageSize,
    tag: {
      name: "",
      category: "",
      customer_journey: "",
      primary_message: "",
    },
    fetching: false,
    tagsCount: 0,
    tagList: [],
    allTags: {},
  },
  tagCategories: {
    selectedTagCategory: null,
    data: [],
  },
  cannedMessages: {
    pageNum: DEFAULT_PAGINATION_INFO.pageNum,
    pageSize: DEFAULT_PAGINATION_INFO.pageSize,
    cannedMessage: {
      name: "",
      type: "",
      text: "",
      media_url: "",
    },
    fetching: false,
    cannedMessagesCount: 0,
    cannedMessagesList: [],
  },
  templateMessages: {
    pageNum: DEFAULT_PAGINATION_INFO.pageNum,
    pageSize: DEFAULT_PAGINATION_INFO.pageSize,
    templateMessage: {
      display_name: "",
      category: "",
      language: "",
      language_code: "",
      name: "",
      tag: "",
      icon_url: null,
      templates: {
        header_type: "",
        header_media_url: "",
        header_text: "",
        body_text: "",
        sample_body: "",
        sample_body_variables: [],
        sample_header_variables: [],
        sample_button_url_variables: [],
        footer_text: "",
        buttons: [],
        carousel_media_type: "",
        carousel_card: [],
      },
      trackingEnabled: false,
    },
    fetching: false,
    templateMessagesCount: 0,
    templateMessagesList: [],
    isSaveBtnClick: false,
  },
  predifinedTemplates: {
    status: "none",
    data: [],
  },
  userAttributes: {
    pageNum: DEFAULT_PAGINATION_INFO.pageNum,
    pageSize: DEFAULT_PAGINATION_INFO.pageSize,
    attribute: {
      attributes: [
        {
          name: "",
        },
      ],
    },
    fetching: false,
    attributesCount: 0,
    attributesList: [],
    activeAttributesList: [],
  },
  manageBillings: {
    isFetchingBillingDateRange: false,
    billingDateRange: [],
  },
  manageBillingAmount: {
    creditBalance: 0,
    isFetchingBillingAmount: false,
    creditData: {},
    transactions: [],
  },
  managePlansList: {
    isFetchingBillingPlansList: false,
    plansList: {},
    plans: [],
  },
  manageUserCountStats: {
    isFetchingUserCountStats: false,
    userCountList: {},
    conversationList: [],
  },
  manageNotificationCounts: {
    isFetchingNotificationCount: false,
    notificationLists: {},
  },
  manageNewBusiness: {
    isFetchingNewBusiness: false,
    businessData: {},
  },
  analytics: {
    period: {
      label: "Last 7 days",
      value: "Last 7 days",
    },
    start_date: moment().startOf("day").subtract({ day: 7 }).format("YYYY-MM-DD"),
    end_date: moment().endOf("day").format("YYYY-MM-DD"),
  },
  analyticsOverview: {
    isFetching: false,
    overviewDailyStats: [],
    overviewTotalStats: [],
  },
  analyticsAgentPerformance: {
    selectedAgentId: "",
    isFetching: false,
    agentDailyStats: [],
    agentTotalStats: [],
  },
  billingPaymentMethodList: {
    paymentList: [],
    totalCount: "",
    isFetching: false,
  },
  paymentMethodAdd: {
    payment_method: {
      service: "whatsapp",
      razorpay_key: "some_randome_key_2e2kw200w2iw0ii0w2",
      razorpay_secret: "some_randome_key_2e2kw200w2iw0ii0w2",
    },
    addPaymentMethodData: {},
    isFetching: false,
  },
  billingAddressList: {
    addressList: [],
    isFetching: false,
    addressListCount: "",
    newAddressBillings: {},
  },
  shippingAddressList: {
    addressList: [],
    isFetchingShippingAddress: false,
    addressListCount: 0,
  },
  getPlansList: {
    activePlansList: [],
    isFetching: false,
    totalCount: "",
    selectedPlan: null,
  },
  manageSubscription: {
    isNewSubscriptionLoading: false,
    isNewSubscriptionAdded: false,
    subscriptionResult: null,
  },
  isActiveSubscriptionList: false,
  activeSubscriptionList: {},
  pushDevice: {
    sound: true,
    isFetching: false,
    deviceList: [],
    deviceCount: 0,
  },
  business: {
    is_onboarded: "",
    name: "",
    business_addresse: "",
    country: "",
    tax_number: "",
    contact_number: "",
    contact_email: "",
    whatsapp_api_status: "",
    wa_api_key: "",
    wa_display_name: "",
    wa_phone_number: "",
    wa_country_code: "",
    business_api_key: "",
    wa_business_logo_url: "",
    wa_business_description: "",
    wa_about_description: "",
    wa_business_website: "",
    provider: "",
    admin: {
      prefix_name: "",
      contact_email: "",
      first_name: "",
      last_name: "",
      phone_number: "",
      country_code: "",
      local_password: "",
      gender: "",
      username: "",
      profile_image_url: "",
    },
  },
  liveChatSettings: {
    workingHours: {
      isFetching: false,
      data: [
        { name: "Mon", isChecked: false },
        { name: "Tue", isChecked: false },
        { name: "Wed", isChecked: false },
        { name: "Thu", isChecked: false },
        { name: "Fri", isChecked: false },
        { name: "Sat", isChecked: false },
        { name: "Sun", isChecked: false },
      ],
    },
    workingConfig: {
      data: {},
      isFetching: false,
    },
    nonWorkingConfig: {
      data: {},
      isFetching: false,
    },
    isAutomateReply: false,
    isAutomateReplyOffHour: false,
    isOptedOutAutomateReply: false,
    isOptedOut: false,
    isOptedIn: false,
    isOptedInAutomateReply: false,
    optOutKeywords: [],
    optInKeywords: [],
    optOutConfig: {
      data: {},
      isFetching: false,
    },
    optInConfig: {
      data: {},
      isFetching: false,
    },
  },
  billingChatStats: {
    status: "none",
    chatStats: {},
  },
  conversationUsage: {
    totalStats: {},
    dailyStats: {},
  },
  qualityRatings: {
    qualityRating: "-",
  },
  usageAndCredit: {
    usageAndCredit: {},
  },
  facebookAccountData: {
    isFetching: false,
    accountData: [],
    accountState: "",
    isActive: "",
  },
  fbPagesAccountData: {
    isFetching: false,
    fePagesData: [],
  },
  regionData: {
    regions: [],
    selectedMedia: "",
    fileUrl: "",
    mediaType: "",
  },
  fbCampaignData: {
    campaign_name: "",
    project_id: "",
    accountId: "",
    wa_phone_number: "",
    page_id: "",
    age_min: 18,
    age_max: "",
    genders: "",
    cities: [],
    daily_budget: "",
    creative: {
      name: "",
      object_story_spec: {
        page_id: "",
        link_data: {
          name: "",
          message: "",
          description: "",
          link: "",
          image_hash: "",
          call_to_action: {
            type: "WHATSAPP_MESSAGE",
            value: {
              app_destination: "WHATSAPP",
            },
          },
        },
      },
    },
  },
  FbAccountMetaData: {
    isFetching: false,
    data: [],
  },
  fbCampaignList: {
    isFetching: false,
    selectedData: null,
    data: [],
  },
  fbCampaignStatus: {
    isFetching: false,
    data: [],
  },
  fbCampaignStatusDetails: {
    isFetching: false,
    data: [],
  },
  metaStatusDetails: {
    accountStatus: "",
    lastTokenGenerate: "",
  },
};

const managePageReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_ALL_AGENT_DETAILS:
      return {
        ...state,
        teamSetting: {
          ...state.teamSetting,
          fetching: true,
          agents: [],
        },
      };
    case FETCH_ALL_AGENT_DETAILS_SUCCESS:
      return {
        ...state,
        teamSetting: {
          ...state.teamSetting,
          fetching: false,
          agentCount: action?.payload?.agentCount || 0,
          agents: action?.payload?.agentList,
        },
      };
    case FETCH_CONVERSATION_USAGE:
      // console.log(action);
      return {
        ...state,
        conversationUsage: {
          ...state.conversationUsage,
          // fetching: false,
          totalStats: action?.data?.totalStats,
          dailyStats: action?.data?.dailyStats,
        },
      };

    case FETCH_CONVERSATION_CREDIT_USAGE:
      return {
        ...state,
        usageAndCredit: {
          ...state?.usageAndCredit,
          ...action?.data,
        },
      };
    case AGENT_DETAILS_FAILURE:
      return {
        ...state,
        teamSetting: {
          ...state.teamSetting,
          fetching: false,
          agent: {},
        },
      };

    case FETCH_AGENT_DETAILS: {
      const getAgent = state.teamSetting?.agents.filter((agent) => agent?._id === action.id)?.[0];
      return {
        ...state,
        teamSetting: {
          ...state.teamSetting,
          agent: getAgent,
        },
      };
    }

    case UPDATE_AGENT_DETAILS: {
      let fullName;
      if (action.keyName === "contact_name") {
        fullName = action.payload.split(" ");
      }
      return {
        ...state,
        teamSetting: {
          ...state.teamSetting,
          agent: {
            ...state.teamSetting?.agent,
            first_name: action.keyName === "contact_name" ? fullName[0] : state.teamSetting?.agent?.first_name,
            last_name: action.keyName === "contact_name" ? (fullName.length > 1 ? fullName[fullName.length - 1] : "") : state.teamSetting?.agent?.last_name,
            contact_name: action.keyName === "contact_name" ? action.payload : state.teamSetting?.agent?.contact_name,
            contact_email: action.keyName === "contact_email" ? action.payload : state.teamSetting?.agent?.contact_email,
            country_code: action.keyName === "country_code" ? action.payload : state.teamSetting?.agent?.country_code,
            phone_number: action.keyName === "phone_number" ? action.payload : state.teamSetting?.agent?.phone_number,
            username: action.keyName === "username" ? action.payload : state.teamSetting?.agent?.username,
            local_password: action.keyName === "local_password" ? action.payload : state.teamSetting?.agent?.local_password,
            role: action.keyName === "role" ? action.payload : state.teamSetting?.agent?.role,
            status: action.keyName === "status" ? action.payload : state.teamSetting?.agent?.status,
            project_id: action.keyName === "project_id" ? action.payload : state.teamSetting?.agent?.project_id,
            business_id: action.keyName === "business_id" ? action.payload : state.teamSetting?.agent?.business_id,
          },
        },
      };
    }

    case INITIAL_AGENT_DETAILS:
      return {
        ...state,
        teamSetting: {
          ...state.teamSetting,
          agent: {
            ...state.teamSetting?.agent,
            contact_name: "",
            contact_email: "",
            country_code: "+91",
            phone_number: "",
            username: "",
            local_password: "",
          },
        },
      };

    case ADD_AGENT_DETAILS_SUCCESS: {
      const newAgent = action?.payload;
      let agentList = _.clone(state.teamSetting?.agents);
      agentList.unshift(newAgent);

      return {
        ...state,
        teamSetting: {
          ...state.teamSetting,
          agents: agentList,
          agent: {},
        },
      };
    }

    case UPDATE_AGENT_DATA_SUCCESS: {
      const updatedAgent = action?.payload;
      let agentList = _.clone(state.teamSetting?.agents);
      const newAgentList = agentList.map((agent) => {
        if (agent?._id === updatedAgent?._id) {
          return updatedAgent;
        }
        return agent;
      });

      return {
        ...state,
        teamSetting: {
          ...state.teamSetting,
          agents: newAgentList,
          agent: {},
        },
      };
    }

    case DELETE_AGENT_DETAILS_SUCCESS: {
      const agentId = action?.payload;
      let agentList = _.clone(state.teamSetting?.agents);
      const newAgentList = agentList.filter((item) => item._id !== agentId);
      return {
        ...state,
        teamSetting: {
          ...state.teamSetting,
          agents: newAgentList,
          agent: {},
        },
      };
    }

    case UPDATE_INITIAL_ALL_AGENT_DATA: {
      return {
        ...state,
        teamSetting: {
          ...state.teamSetting,
          pageNum: action.keyName === "pageNum" ? action.keyValue : state.teamSetting?.pageNum,
          pageSize: action.keyName === "pageSize" ? action.keyValue : state.teamSetting?.pageSize,
        },
      };
    }

    //Tags

    case FETCH_ALL_TAGS_DETAILS:
      return {
        ...state,
        tags: {
          ...state.tags,
          fetching: true,
          tagList: [],
        },
      };

    case UPDATE_INITIAL_TAGS_DATA: {
      return {
        ...state,
        tags: {
          ...state.tags,
          pageNum: action.keyName === "pageNum" ? action.keyValue : state.tags?.pageNum,
          pageSize: action.keyName === "pageSize" ? action.keyValue : state.tags?.pageSize,
        },
      };
    }

    case FETCH_ALL_TAGS_DETAILS_SUCCESS:
      let allTags = {};
      action?.payload?.tagList.forEach((item) => {
        allTags[item._id] = item;
      });
      return {
        ...state,
        tags: {
          ...state.tags,
          fetching: false,
          tagsCount: action?.payload?.tagCount,
          tagList: action?.payload?.tagList,
          allTags,
        },
      };

    case TAG_DETAILS_FAILURE:
      return {
        ...state,
        tags: {
          ...state.tags,
          fetching: false,
          tag: {},
        },
      };

    case FETCH_TAG_DETAILS: {
      const getTag = state.tags?.tagList.filter((tag) => tag?._id === action.id)?.[0];
      return {
        ...state,
        tags: {
          ...state.tags,
          tag: getTag,
        },
      };
    }

    case UPDATE_TAG_DETAILS: {
      return {
        ...state,
        tags: {
          ...state.tags,
          tag: {
            ...state.tags?.tag,
            name: action.keyName === "name" ? action.payload : state.tags?.tag?.name,
            category: action.keyName === "category" ? action.payload : state.tags?.tag?.category,
            customer_journey: action.keyName === "customer_journey" ? action.payload : state.tags?.tag?.customer_journey,
            primary_message: action.keyName === "primary_message" ? action.payload : state.tags?.tag?.primary_message,
          },
        },
      };
    }

    case INITIAL_TAG_DETAILS:
      return {
        ...state,
        tags: {
          ...state.tags,
          tag: {
            ...state.tags?.tag,
            name: "",
            category: "",
            customer_journey: "",
            primary_message: "",
          },
        },
      };

    case ADD_TAG_DETAILS_SUCCESS: {
      const newTag = action?.payload;
      let tagList = _.clone(state.tags?.tagList);
      tagList.unshift(newTag);

      return {
        ...state,
        tags: {
          ...state.tags,
          tagList: tagList,
          tag: {},
        },
      };
    }

    case UPDATE_TAG_DATA_SUCCESS: {
      const updatedTag = action?.payload;
      let tagList = _.clone(state.tags?.tagList);
      const newTagList = tagList.map((tag) => {
        if (tag?._id === updatedTag?._id) {
          return updatedTag;
        }
        return tag;
      });

      return {
        ...state,
        tags: {
          ...state.tags,
          tagList: newTagList,
          tag: {},
        },
      };
    }

    case DELETE_TAG_DETAILS_SUCCESS: {
      const tagId = action?.payload;
      let tagList = _.clone(state.tags?.tagList);
      const newTagList = tagList.filter((item) => item._id !== tagId);

      return {
        ...state,
        tags: {
          ...state.tags,
          tagList: newTagList,
          // tagsCount: newTagList?.length,
          tag: {},
        },
      };
    }

    //Canned Message

    case UPDATE_INITIAL_CANNED_DATA: {
      return {
        ...state,
        cannedMessages: {
          ...state.cannedMessages,
          pageNum: action.keyName === "pageNum" ? action.keyValue : state.cannedMessages?.pageNum,
          pageSize: action.keyName === "pageSize" ? action.keyValue : state.cannedMessages?.pageSize,
        },
      };
    }

    case FETCH_ALL_CANNED_MESSAGE_DETAILS:
      return {
        ...state,
        cannedMessages: {
          ...state.cannedMessages,
          fetching: true,
        },
      };

    case FETCH_ALL_CANNED_MESSAGE_DETAILS_SUCCESS:
      return {
        ...state,
        cannedMessages: {
          ...state.cannedMessages,
          fetching: false,
          cannedMessagesCount: action?.payload?.count,
          cannedMessagesList: action?.payload?.list,
        },
      };

    case CANNED_MESSAGE_DETAILS_FAILURE:
      return {
        ...state,
        cannedMessages: {
          ...state.cannedMessages,
          fetching: false,
          cannedMessage: {},
        },
      };

    case FETCH_CANNED_MESSAGE_DETAILS: {
      const getDetails = state.cannedMessages?.cannedMessagesList.filter((item) => item?._id === action.id)?.[0];
      return {
        ...state,
        cannedMessages: {
          ...state.cannedMessages,
          cannedMessage: getDetails,
        },
      };
    }

    case UPDATE_CANNED_MESSAGE_DETAILS: {
      return {
        ...state,
        cannedMessages: {
          ...state.cannedMessages,
          cannedMessage: {
            ...state.cannedMessages?.cannedMessage,
            name: action.keyName === "name" ? action.payload : state.cannedMessages?.cannedMessage?.name,
            type: action.keyName === "type" ? action.payload : state.cannedMessages?.cannedMessage?.type,
            text: action.keyName === "text" ? action.payload : state.cannedMessages?.cannedMessage?.text,
            media_url: action.keyName === "media_url" ? action.payload : state.cannedMessages?.cannedMessage?.media_url,
          },
        },
      };
    }

    case INITIAL_CANNED_MESSAGE_DETAILS:
      return {
        ...state,
        cannedMessages: {
          ...state.cannedMessages,
          cannedMessage: {
            ...state.cannedMessages?.cannedMessage,
            name: "",
            type: "",
            text: "",
          },
        },
      };

    case ADD_CANNED_MESSAGE_DETAILS_SUCCESS: {
      const newData = action?.payload;
      let cannedMessagesList = _.clone(state.cannedMessages?.cannedMessagesList);
      cannedMessagesList.unshift(newData);

      return {
        ...state,
        cannedMessages: {
          ...state.cannedMessages,
          cannedMessagesList: cannedMessagesList,
          cannedMessagesCount: cannedMessagesList?.length,
          cannedMessage: {},
        },
      };
    }

    case UPDATE_CANNED_MESSAGE_DATA_SUCCESS: {
      const updatedData = action?.payload;
      let cannedMessagesList = _.clone(state.cannedMessages?.cannedMessagesList);
      const newList = cannedMessagesList.map((item) => {
        if (item?._id === updatedData?._id) {
          return updatedData;
        }
        return item;
      });

      return {
        ...state,
        cannedMessages: {
          ...state.cannedMessages,
          cannedMessagesList: newList,
          cannedMessage: {},
        },
      };
    }

    case DELETE_CANNED_MESSAGE_DETAILS_SUCCESS: {
      const id = action?.payload;
      let cannedMessagesList = _.clone(state.cannedMessages?.cannedMessagesList);
      const newList = cannedMessagesList.filter((item) => item._id !== id);

      return {
        ...state,
        cannedMessages: {
          ...state.cannedMessages,
          cannedMessagesList: newList,
          cannedMessagesCount: newList?.length,
          cannedMessage: {},
        },
      };
    }

    //Template Message
    case UPDATE_BUTTON_CLICKED:
      return {
        ...state,
        templateMessages: {
          ...state.templateMessages,
          isSaveBtnClick: action.isSaveBtnClick,
        },
      };

    case SYNC_TEMPLATE_MESSAGE:
    case ADD_TEMPLATE_MESSAGE_DETAILS:
    case FETCH_ALL_TEMPLATE_MESSAGE_DETAILS:
      return {
        ...state,
        templateMessages: {
          ...state.templateMessages,
          fetching: true,
        },
      };

    case SYNC_TEMPLATE_MESSAGE_SUCCESS:
    case SYNC_TEMPLATE_MESSAGE_FAILURE:
    case ADD_TEMPLATE_MESSAGE_DETAILS_FAILURE:
      return {
        ...state,
        templateMessages: {
          ...state.templateMessages,
          fetching: false,
        },
      };

    case FETCH_ALL_TEMPLATE_MESSAGE_DETAILS_SUCCESS:
      return {
        ...state,
        templateMessages: {
          ...state.templateMessages,
          fetching: false,
          templateMessagesCount: action?.payload?.template_messagesCount,
          templateMessagesList: action?.payload?.template_messagesList,
        },
      };

    case TEMPLATE_MESSAGE_DETAILS_FAILURE:
      return {
        ...state,
        templateMessages: {
          ...state.templateMessages,
          fetching: false,
          templateMessage: {},
        },
      };

    case FETCH_TEMPLATE_MESSAGE_DETAILS: {
      const getDetails = state.templateMessages?.templateMessagesList.filter((item) => item?._id === action.id)?.[0];
      return {
        ...state,
        templateMessages: {
          ...state.templateMessages,
          templateMessage: getDetails,
        },
      };
    }

    case UPDATE_TEMPLATE_MESSAGE_DETAILS: {
      return {
        ...state,
        templateMessages: {
          ...state.templateMessages,
          templateMessage: {
            ...state.templateMessages?.templateMessage,
            name: action.keyName === "name" ? action.payload : state.templateMessages?.templateMessage?.name,
            trackingEnabled: action.keyName === "trackingEnabled" ? action.payload : state.templateMessages?.templateMessage?.trackingEnabled,
            display_name: action.keyName === "display_name" ? action.payload : state.templateMessages?.templateMessage?.display_name,
            category: action.keyName === "category" ? action.payload : state.templateMessages?.templateMessage?.category,
            language: action.keyName === "language" ? action.payload : state.templateMessages?.templateMessage?.language,
            language_code: action.keyName === "language_code" ? action.payload : state.templateMessages?.templateMessage?.language_code,
            tag: action.keyName === "tag" ? action.payload : state.templateMessages?.templateMessage?.tag,
            icon_url: action.keyName === "icon_url" ? action.payload : state.templateMessages?.templateMessage?.icon_url,
            templates: {
              ...state.templateMessages?.templateMessage?.templates,
              header_type: action.keyName === "header_type" ? action.payload : state.templateMessages?.templateMessage?.templates?.header_type,
              header_media_url: action.keyName === "header_media_url" ? action.payload : state.templateMessages?.templateMessage?.templates?.header_media_url,
              header_text: action.keyName === "header_text" ? action.payload : state.templateMessages?.templateMessage?.templates?.header_text,
              body_text: action.keyName === "body_text" ? action.payload : state.templateMessages?.templateMessage?.templates?.body_text,
              sample_body: action.keyName === "sample_body" ? action.payload : state.templateMessages?.templateMessage?.templates?.sample_body,
              sample_body_variables:
                action.keyName === "sample_body_variables" ? action.payload : state.templateMessages?.templateMessage?.templates?.sample_body_variables,
              sample_button_url_variables:
                action.keyName === "sample_button_url_variables"
                  ? action.payload
                  : state.templateMessages?.templateMessage?.templates?.sample_button_url_variables,
              sample_header_variables:
                action.keyName === "sample_header_variables" ? action.payload : state.templateMessages?.templateMessage?.templates?.sample_header_variables,
              footer_text: action.keyName === "footer_text" ? action.payload : state.templateMessages?.templateMessage?.templates?.footer_text,
              buttons: action.keyName === "buttons" ? action.payload || [] : state.templateMessages?.templateMessage?.templates?.buttons,
              carousel_media_type:
                action.keyName === "carousel_media_type" ? action.payload : state.templateMessages?.templateMessage?.templates?.carousel_media_type,
              carousel_card: action.keyName === "carousel_card" ? action.payload || [] : state.templateMessages?.templateMessage?.templates?.carousel_card,
              carousel_card:
                action.keyName === "carousel_card" ? action.payload || [] : state.templateMessages?.templateMessage?.templates?.carousel_card || [],
            },
          },
        },
      };
    }

    case UPDATE_INITIAL_TEMPLATE_DATA: {
      return {
        ...state,
        templateMessages: {
          ...state.templateMessages,
          pageNum: action.keyName === "pageNum" ? action.keyValue : state.templateMessages?.pageNum,
          pageSize: action.keyName === "pageSize" ? action.keyValue : state.templateMessages?.pageSize,
        },
      };
    }

    case INITIAL_TEMPLATE_MESSAGE_DETAILS:
      return {
        ...state,
        templateMessages: {
          ...state.templateMessages,
          templateMessage: {
            display_name: "",
            category: "",
            language: "",
            language_code: "",
            name: "",
            templates: {
              header_type: "",
              header_media_url: "",
              header_text: "",
              body_text: "",
              sample_body: "",
              footer_text: "",
              buttons: [],
              carousel_media_type: "image",
              carousel_card: [],
            },
            trackingEnabled: false,
          },
        },
      };

    case ADD_TEMPLATE_MESSAGE_DETAILS_SUCCESS: {
      const newData = action?.payload;
      let templateMessagesList = _.clone(state.templateMessages?.templateMessagesList);
      templateMessagesList.unshift(newData);

      return {
        ...state,
        templateMessages: {
          ...state.templateMessages,
          fetching: false,
          templateMessagesList: templateMessagesList,
          templateMessage: {},
        },
      };
    }

    case UPDATE_TEMPLATE_MESSAGE_DATA_SUCCESS: {
      const updatedData = action?.payload;
      let templateMessagesList = _.clone(state.templateMessages?.templateMessagesList);
      const newList = templateMessagesList.map((item) => {
        if (item?._id === updatedData?._id) {
          return updatedData;
        }
        return item;
      });

      return {
        ...state,
        templateMessages: {
          ...state.templateMessages,
          templateMessagesList: newList,
          templateMessage: {},
        },
      };
    }

    case DELETE_TEMPLATE_MESSAGE_DETAILS_SUCCESS: {
      const id = action?.payload;
      let templateMessagesList = _.clone(state.templateMessages?.templateMessagesList);
      const newList = templateMessagesList.filter((item) => item._id !== id);
      return {
        ...state,
        templateMessages: {
          ...state.templateMessages,
          templateMessagesList: newList,
          templateMessage: {},
        },
      };
    }

    //user-attribute
    case UPDATE_INITIAL_USER_ATTRIBUTE_DATA: {
      return {
        ...state,
        userAttributes: {
          ...state.userAttributes,
          pageNum: action.keyName === "pageNum" ? action.keyValue : state.userAttributes?.pageNum,
          pageSize: action.keyName === "pageSize" ? action.keyValue : state.userAttributes?.pageSize,
        },
      };
    }

    case FETCH_ALL_USER_ATTRIBUTE_DETAILS:
      return {
        ...state,
        userAttributes: {
          ...state.userAttributes,
          fetching: true,
        },
      };

    case FETCH_ALL_USER_ATTRIBUTE_DETAILS_SUCCESS:
      return {
        ...state,
        userAttributes: {
          ...state.userAttributes,
          fetching: false,
          attributesList: !!action?.payload?.attributes ? action?.payload?.attributes : [],
          activeAttributesList: !!action?.payload?.attributes ? action?.payload?.attributes?.filter((item) => !item.is_deleted) : [],
          attributesCount: action?.payload?.attributes?.filter((item) => !item.is_deleted).length,
        },
      };

    case USER_ATTRIBUTE_DETAILS_FAILURE:
      return {
        ...state,
        userAttributes: {
          ...state.userAttributes,
          fetching: false,
          attribute: {},
        },
      };

    case FETCH_USER_ATTRIBUTE_DETAILS: {
      const getDetails = state.userAttributes?.attributesList.filter((item) => item?._id === action.id)?.[0];
      return {
        ...state,
        userAttributes: {
          ...state.userAttributes,
          attribute: getDetails,
        },
      };
    }

    case UPDATE_USER_ATTRIBUTE_DETAILS: {
      return {
        ...state,
        userAttributes: {
          ...state.userAttributes,
          attribute: {
            ...state.userAttributes?.attribute,
            attributes: [
              {
                ...state.userAttributes?.attribute?.attributes[0],
                name: action.keyName === "name" ? action.payload : state.userAttributes?.attribute?.attributes?.[0]?.name,
                data_type: action.keyName === "data_type" ? action.payload : state.userAttributes?.attribute?.attributes?.[0]?.data_type,
              },
            ],
          },
        },
      };
    }

    case INITIAL_USER_ATTRIBUTE_DETAILS:
      return {
        ...state,
        userAttributes: {
          ...state.userAttributes,
          attribute: {},
        },
      };

    case ADD_USER_ATTRIBUTE_DETAILS_SUCCESS: {
      const newData = action?.payload;
      let attributesList = [...state.userAttributes?.attributesList];
      attributesList.unshift(newData);

      return {
        ...state,
        userAttributes: {
          ...state.userAttributes,
          attributesList: attributesList,
          attribute: {},
        },
      };
    }

    case UPDATE_USER_ATTRIBUTE_DATA_SUCCESS: {
      return {
        ...state,
        userAttributes: {
          ...state.userAttributes,
          attributesList: action?.payload?.attributes ? action?.payload?.attributes : [],
          activeAttributesList: action?.payload?.attributes?.filter((item) => !item.is_deleted),
          attributesCount: action?.payload?.attributes?.filter((item) => !item.is_deleted).length,
          attribute: {},
        },
      };
    }

    case DELETE_USER_ATTRIBUTE_DETAILS_SUCCESS: {
      const id = action?.payload;
      const attributesList = _.clone(state.userAttributes?.attributesList);
      const newList = attributesList.map((item) => {
        if (item._id === id) {
          item.is_deleted = true;
        }
        return item;
      });

      return {
        ...state,
        userAttributes: {
          ...state.userAttributes,
          attribute: {},
          fetching: false,
          attributesList: newList ? newList : [],
          activeAttributesList: newList?.filter((item) => !item.is_deleted),
          attributesCount: newList?.filter((item) => !item.is_deleted).length,
        },
      };
    }

    case BILLING_DATE_RANGE: {
      return {
        ...state,
        billingDateRange: action?.dateRange,
        isFetchingBillingDateRange: action?.isFetchingBillingDateRange,
      };
    }

    case BILLING_DATE_RANGE_STATUS: {
      return {
        ...state,
        isFetchingBillingDateRange: action?.isFetchingBillingDateRange,
      };
    }
    case BILLING_CREDIT_AMOUNT: {
      return {
        ...state,
        manageBillingAmount: {
          ...state.manageBillingAmount,
          creditData: action?.creditData,
          transactions: action?.creditData?.transactions,
        },
      };
    }
    case BILLING_CREDIT_AMOUNT_STATUS: {
      return {
        ...state,
        isFetchingBillingAmount: action?.isFetchingBillingAmount,
      };
    }
    case BILLING_PAYMANT_PLANS_LIST: {
      return {
        ...state,
        // plans: action?.plans
        managePlansList: {
          ...state.managePlansList,
          plansList: action?.plansList,
          plans: action?.plansList?.plans,
        },
      };
    }
    case BILLING_PAYMANT_PLANS_LIST_STATUS: {
      return {
        ...state,
        isFetchingBillingPlansList: action?.isFetchingBillingPlansList,
      };
    }
    case BILLING_USER_COUNT_STATS: {
      return {
        ...state,
        manageUserCountStats: {
          ...state.manageUserCountStats,
          userCountList: action?.userCountList,
          conversationList: action?.userCountList?.conversationList,
        },
      };
    }
    case BILLING_USER_COUNT_STATUS: {
      return {
        ...state,
        isFetchingUserCountStats: action?.isFetchingUserCountStats,
      };
    }
    case BILLING_NOTIFICATION_COUNT: {
      return {
        ...state,
        manageNotificationCounts: {
          ...state.manageNotificationCounts,
          notificationLists: action?.notificationLists,
        },
      };
    }
    case BILLING_NOTIFICATION_COUNT_STATUS: {
      return {
        ...state,
        isFetchingNotificationCount: action?.isFetchingNotificationCount,
      };
    }

    case FETCH_NEW_BUSINESS: {
      return {
        ...state,
        manageNewBusiness: {
          ...state.manageNewBusiness,
          businessData: action?.businessData,
        },
      };
    }
    case FETCH_NEW_BUSINESS_STATUS: {
      return {
        ...state,
        isFetchingNewBusiness: action?.isFetchingNewBusiness,
      };
    }

    case ANALYTICS_BODY_DETAILS: {
      return {
        ...state,
        analytics: {
          ...state.analytics,
          period: action.keyName === "period" ? action.keyValue : state.analytics?.period,
          start_date: action.keyName === "start_date" ? action.keyValue : state.analytics?.start_date,
          end_date: action.keyName === "end_date" ? action.keyValue : state.analytics?.end_date,
        },
      };
    }

    case FETCH_ANALYTICS_OVERVIEW_STATS:
    case FETCH_ANALYTICS_OVERVIEW_STATS_FAILURE: {
      return {
        ...state,
        analyticsOverview: {
          ...state.analyticsOverview,
          isFetching: action?.isFetching,
        },
      };
    }

    case FETCH_ANALYTICS_OVERVIEW_STATS_SUCCESS: {
      return {
        ...state,
        analyticsOverview: {
          isFetching: action?.isFetching,
          overviewDailyStats: action?.overviewDailyStats,
          overviewTotalStats: action?.overviewTotalStats,
        },
      };
    }

    case FETCH_ANALYTICS_AGENT_PERFORMANCE_STATS:
    case FETCH_ANALYTICS_AGENT_PERFORMANCE_STATS_FAILURE: {
      return {
        ...state,
        analyticsAgentPerformance: {
          ...state.analyticsAgentPerformance,
          isFetching: action?.isFetching,
        },
      };
    }

    case FETCH_ANALYTICS_AGENT_PERFORMANCE_STATS_SUCCESS: {
      return {
        ...state,
        analyticsAgentPerformance: {
          selectedAgentId: state.analyticsAgentPerformance?.selectedAgentId,
          isFetching: action?.isFetching,
          agentDailyStats: action?.agentDailyStats,
          agentTotalStats: action?.agentTotalStats,
          agentDailyStatusData: action?.agentDailyStatusData,
        },
      };
    }

    case ANALYTICS_AGENT_PERFORMANCE_DETAILS: {
      return {
        ...state,
        analyticsAgentPerformance: {
          ...state.analyticsAgentPerformance,
          selectedAgentId: action.keyName === "selectedAgentId" ? action.keyValue : state.analyticsAgentPerformance?.selectedAgentId,
        },
      };
    }

    case LIST_BILLING_PAYMENT_METHOD_FAILURE: {
      return {
        ...state,
        billingPaymentMethodList: {
          ...state.billingPaymentMethodList,
          isFetching: action?.isFetching,
        },
      };
    }

    case LIST_BILLING_PAYMENT_METHOD_SUCCESS: {
      return {
        ...state,
        billingPaymentMethodList: {
          isFetching: action?.isFetching,
          paymentList: action?.paymentList,
          totalCount: action?.paymentListStats,
        },
      };
    }

    case ADD_BILLING_PAYMENT_METHOD_FAILURE: {
      return {
        ...state,
        paymentMethodAdd: {
          ...state.paymentMethodAdd,
          isFetching: action?.isFetching,
        },
      };
    }

    case ADD_BILLING_PAYMENT_METHOD_SUCCESS: {
      return {
        ...state,
        paymentMethodAdd: {
          isFetching: action?.isFetching,
          addPaymentMethodData: action?.paymentList,
        },
      };
    }

    case LIST_BILLING_ADDRESS_FAILURE: {
      if (action.address_type === "billing") {
        return {
          ...state,
          billingAddressList: {
            ...state.billingAddressList,
            isFetching: action?.isFetching,
          },
        };
      } else {
        return {
          ...state,
          shippingAddressList: {
            ...state.shippingAddressList,
            isFetchingShippingAddress: action?.isFetchingShippingAddress,
          },
        };
      }
    }

    case LIST_BILLING_ADDRESS_SUCCESS: {
      if (action.address_type === "billing") {
        return {
          ...state,
          billingAddressList: {
            ...state.billingAddressList,
            isFetching: action?.isFetching,
            addressList: action?.billing_address_List,
            addressListCount: action?.billing_address_List_Count,
          },
        };
      }
      return {
        ...state,
        shippingAddressList: {
          ...state.shippingAddressList,
          isFetchingShippingAddress: action?.isFetchingShippingAddress,
          addressList: action?.shipping_address_List,
          addressListCount: action?.shipping_address_List_Count,
        },
      };
    }

    case ADD_NEW_BILLING_ADDRESS_FAILURE: {
      if (action.address_type === "billing") {
        return {
          ...state,
          billingAddressList: {
            ...state.billingAddressList,
            isFetching: action?.isFetching,
          },
        };
      }
      return {
        ...state,
        shippingAddressList: {
          ...state.shippingAddressList,
          isFetchingShippingAddress: action?.isFetchingShippingAddress,
        },
      };
    }

    case ADD_NEW_BILLING_ADDRESS_SUCCESS: {
      if (action.address_type === "billing") {
        return {
          ...state,
          billingAddressList: {
            ...state.billingAddressList,
            isFetching: action?.isFetching,
            addressList: [action?.billing_address],
          },
        };
      }
      return {
        ...state,
        shippingAddressList: {
          ...state.shippingAddressList,
          isFetchingShippingAddress: action?.isFetching,
          addressList: [action?.shipping_address],
        },
      };
    }

    case NEW_BILLING_ADDRESS_UPDATE_SUCCESS: {
      if (action.address_type === "billing") {
        return {
          ...state,
          billingAddressList: {
            ...state?.billingAddressList,
            addressList: [action.billing_address],
          },
        };
      }
      return {
        ...state,
        shippingAddressList: {
          ...state?.shippingAddressList,
          addressList: [action.shipping_address],
        },
      };
    }

    case NEW_BILLING_ADDRESS_UPDATE_FAILURE: {
      if ((action.address_type = "billing")) {
        return {
          ...state,
          billingAddressList: {
            ...state.billingAddressList,
            isFetching: action?.isFetching,
          },
        };
      }
      return {
        ...state,
        shippingAddressList: {
          ...state.shippingAddressList,
          isFetchingShippingAddress: action?.isFetchingShippingAddress,
        },
      };
    }

    case BILLING_PLAN_LIST_FAILURE: {
      return {
        ...state,
        getPlansList: {
          ...state.getPlansList,
          isFetching: action?.isFetching,
        },
      };
    }

    case BILLING_PLAN_LIST_SUCCESS: {
      return {
        ...state,
        getPlansList: {
          isFetching: action?.isFetching,
          activePlansList: action?.activePlansList,
          totalCount: action?.activePlansListCount,
        },
      };
    }

    case ACTIVE_SUBSCRIPTION_LIST_DETAILS: {
      return {
        ...state,
        isActiveSubscriptionList: action.isActiveSubscriptionList,
      };
    }

    case ACTIVE_SUBSCRIPTION_LIST_SUCCESS: {
      return {
        ...state,
        isActiveSubscriptionList: action.isActiveSubscriptionList,
        activeSubscriptionList: action.payload,
      };
    }

    case DETAILS_PUSH_DEVICE_STATUS:
      return {
        ...state,
        pushDevice: {
          ...state.pushDevice,
          isFetching: action?.isFetching,
        },
      };

    case LIST_PUSH_DEVICE_SUCCESS: {
      return {
        ...state,
        pushDevice: {
          ...state.pushDevice,
          isFetching: action?.isFetching,
          deviceList: action?.list,
          deviceCount: action?.count,
        },
      };
    }

    case ADD_PUSH_DEVICE_SUCCESS: {
      const newData = action?.details;
      let updatedList = _.clone(state.pushDevice?.deviceList);
      updatedList.unshift(newData);

      return {
        ...state,
        pushDevice: {
          isFetching: action?.isFetching,
          deviceList: updatedList,
          deviceCount: updatedList?.length,
        },
      };
    }

    case UPDATE_PUSH_DEVICE_SUCCESS: {
      const updatedData = action?.details;
      let deviceList = _.clone(state.pushDevice?.deviceList);
      const newDeviceList = deviceList.map((data) => {
        if (data?._id === updatedData?._id) {
          return updatedData;
        }
        return data;
      });
      return {
        ...state,
        pushDevice: {
          ...state.pushDevice,
          isFetching: action?.isFetching,
          deviceList: newDeviceList || [],
        },
      };
    }

    case DELETE_PUSH_DEVICE_SUCCESS: {
      const deviceId = action?.id;
      const deviceList = _.clone(state.pushDevice?.deviceList);
      const newList = deviceList.filter((item) => item._id !== deviceId);

      return {
        ...state,
        pushDevice: {
          isFetching: action?.isFetching,
          deviceList: newList,
          deviceCount: newList?.length,
        },
      };
    }

    case UPDATE_PUSH_DEVICE_SOUND: {
      return {
        ...state,
        pushDevice: {
          ...state.pushDevice,
          sound: action.payload,
        },
      };
    }

    // case UPDATE_BUSINESS_DETAILS: {
    //   if (action.adminFlag) {
    //     console.log("inside admin state");
    //     return {
    //       ...state,
    //       business: {
    //         ...state.business,
    //         [action.keyName]: action.keyValue,
    //         admin: {
    //           ...state.business.admin,
    //           [action.keyName]: action.keyValue,
    //         },
    //       },
    //     };
    //   } else {
    //     console.log("inside business");
    //     return {
    //       ...state,
    //       business: {
    //         ...state.business,
    //         [action.keyName]: action.keyValue,
    //       },
    //     };
    //   }
    // }

    case UPDATE_BUSINESS_ADMIN_DETAILS:
      return {
        ...state,
        business: {
          ...state.business,
          admin: {
            ...state.business.admin,
            [action.keyName]: action.keyValue,
          },
        },
      };
    case UPDATE_BUSINESS_DETAILS:
      return {
        ...state,
        business: {
          ...state.business,
          [action.keyName]: action.keyValue,
        },
      };

    case UPDATE_BUSINESS_DATA: {
      return {
        ...state,
        business: action?.businessData,
      };
    }

    case UPDATE_BUSINESS_DETAILS_EMPTY: {
      console.log("UPDATE_BUSINESS_DETAILS_EMPTY");
      return {
        ...state,
        business: {
          is_onboarded: "",
          name: "",
          business_addresse: "",
          country: "",
          tax_number: "",
          contact_number: "",
          contact_email: "",
          whatsapp_api_status: "",
          wa_api_key: "",
          wa_display_name: "",
          wa_phone_number: "",
          wa_country_code: "",
          business_api_key: "",
          wa_business_logo_url: "",
          wa_business_description: "",
          wa_about_description: "",
          wa_business_website: "",
          provider: "",
          admin: {
            prefix_name: "",
            contact_email: "",
            first_name: "",
            last_name: "",
            phone_number: "",
            country_code: "",
            local_password: "",
            gender: "",
            username: "",
            profile_image_url: "",
          },
        },
      };
    }

    case FETCHING_WORKING_HOURS_FROM_LIVE_CHAT_SETTINGS:
      return {
        ...state,
        liveChatSettings: {
          ...state.liveChatSettings,
          workingHours: {
            ...state.liveChatSettings.workingHours,
            isFetching: action.isFetching,
          },
        },
      };
    case FETCH_WORKING_HOURS_FROM_LIVE_CHAT_SETTINGS:
      return {
        ...state,
        liveChatSettings: {
          ...state.liveChatSettings,
          workingHours: {
            ...action.workingHours,
          },
        },
      };
    case UPDTAE_LIVE_CHAT_SETTINGS_CONFIG:
      return {
        ...state,
        liveChatSettings: {
          ...state.liveChatSettings,
          [action.dataType]: {
            ...action.data,
          },
        },
      };
    case UPDTAING_LIVE_CHAT_SETTINGS_CONFIG:
      return {
        ...state,
        liveChatSettings: {
          ...state.liveChatSettings,
          [action.dataType]: {
            ...state.liveChatSettings[action.dataType],
            isFetching: action.isFetching,
          },
        },
      };
    case UPDATING_OPT_OUT_CONFIG_DATA:
      return {
        ...state,
        liveChatSettings: {
          ...state.liveChatSettings,
          [action.dataType]: {
            ...state.liveChatSettings[action.dataType],
            isFetching: action.isFetching,
          },
        },
      };
    case UPDATE_OPT_OUT_CONFIG_DATA:
      return {
        ...state,
        liveChatSettings: {
          ...state.liveChatSettings,
          [action.dataType]: {
            ...action.data,
          },
        },
      };

    case FETCHING_LIVE_CHAT_SETTINGS:
      return {
        ...state,
        liveChatSettings: {
          ...state.liveChatSettings,
          workingConfig: {
            ...state.liveChatSettings.workingConfig,
            isFetching: action.isFetching,
          },
          nonWorkingConfig: {
            ...state.liveChatSettings.nonWorkingConfig,
            isFetching: action.isFetching,
          },
          optOutConfig: {
            ...state.liveChatSettings.optOutConfig,
            isFetching: action.isFetching,
          },
        },
      };
    case UPDATE_LIVE_CHAT_SETTINGS_AUTO_REPLY:
      return {
        ...state,
        liveChatSettings: {
          ...state.liveChatSettings,
          isAutomateReply: action.isAutomateReply,
          isAutomateReplyOffHour: action.isAutomateReplyOffHour,
        },
      };
    case UPDATE_IS_OPTED_OUT_AUTOMATE_REPLY:
      return {
        ...state,
        liveChatSettings: {
          ...state.liveChatSettings,
          isOptedOutAutomateReply: action.isOptedOutAutomateReply,
          isOptedOut: action.isOptedOut,
          isOptedIn: action.isOptedIn,
          isOptedInAutomateReply: action.isOptedInAutomateReply,
        },
      };
    case OPT_OUT_KEYWORDS:
      return {
        ...state,
        liveChatSettings: {
          ...state.liveChatSettings,
          optOutKeywords: action.optOutKeywords,
        },
      };
    case OPT_IN_KEYWORDS:
      return {
        ...state,
        liveChatSettings: {
          ...state.liveChatSettings,
          optInKeywords: action.optInKeywords,
        },
      };
    case FETCH_ALL_TAG_CATEGORIES:
      return {
        ...state,
        tagCategories: {
          ...state.tagCategories,
          data: [...action.tagCategories],
        },
      };
    case ADD_NEW_TAG_CATEGORY:
      return {
        ...state,
        tagCategories: {
          ...state.tagCategories,
          selectedTagCategory: action.selectedTagCategory,
        },
      };
    case FETCH_PREDIFINED_TEMPLATES:
      return {
        ...state,
        predifinedTemplates: {
          status: action.status,
          data: action.data,
        },
      };
    case UPDATE_TEMPLATE_MESSAGE:
      return {
        ...state,
        templateMessages: {
          ...state.templateMessages,
          templateMessage: {
            ...(action.data ?? initialState.templateMessages.templateMessage),
          },
        },
      };
    case FETCH_BILLING_STATS:
      return {
        ...state,
        billingChatStats: {
          ...state.billingChatStats,
          chatStats: action.data,
          status: action.status,
        },
      };
    case FETCH_BILLING_STATS_STATUS:
      return {
        ...state,
        billingChatStats: {
          chatStats: {},
          status: action.status,
        },
      };

    case GET_QUALITY_RATING: {
      return {
        ...state,
        qualityRatings: {
          qualityRating: action.qualityRating === "GREEN" ? "High" : action.qualityRating === "YELLOW" ? "Medium" : "Low",
        },
      };
    }
    case FETCH_FB_ACCOUNT_DATA: {
      // console.log("action", action);
      return {
        ...state,
        facebookAccountData: {
          isFetching: action?.isFetching,
          accountData: action?.accountData,
          accountState: action?.accountState,
          isActive: action?.isActive,
        },
      };
    }
    case FETCH_FB_PAGES_ACCOUNT_DATA: {
      return {
        ...state,
        fbPagesAccountData: {
          isFetching: action?.isFetching,
          fbPageData: action?.adAccountData,
        },
      };
    }
    case FETCH_FB_AD_ACCOUNT_FORM_META: {
      return {
        ...state,
        FbAccountMetaData: {
          isFetching: action?.isFetching,
          data: action?.data,
        },
      };
    }
    case UPDATE_FB_CAMPAIGN_DATA: {
      // console.log("action", action);
      return {
        ...state,
        fbCampaignData: {
          ...state.fbCampaignData,
          ...action.payload,
        },
      };
    }
    case UPDATE_REGION_DATA: {
      // console.log("action", action);
      return {
        ...state,
        regionData: {
          ...state.regionData,
          [action.keyName]: action.keyValue,
        },
      };
    }
    case CLEAR_FB_CAMPAIGN_DATA: {
      // console.log("action", action);
      return {
        ...state,
        fbCampaignData: {
          campaign_name: "",
          project_id: "",
          wa_phone_number: "",
          accountId: "",
          page_id: "",
          age_min: 18,
          age_max: "",
          genders: "",
          cities: [],
          daily_budget: "",
          creative: {
            name: "",
            object_story_spec: {
              page_id: "",
              link_data: {
                name: "",
                message: "",
                description: "",
                link: "",
                image_hash: "",
                call_to_action: {
                  type: "WHATSAPP_MESSAGE",
                  value: {
                    app_destination: "WHATSAPP",
                  },
                },
              },
            },
          },
        },
      };
    }
    case CLEAR_REGION_DATA: {
      return {
        ...state,
        regionData: {
          regions: [],
          selectedMedia: "",
          fileUrl: "",
          mediaType: "",
        },
      };
    }
    case FETCH_FB_CAMPAIGN_LIST: {
      return {
        ...state,
        fbCampaignList: {
          ...state.fbCampaignList,
          isFetching: action.isFetching,
          data: action.data,
        },
      };
    }
    case UPDATE_SELECTED_CAMPAIGN: {
      return {
        ...state,
        fbCampaignList: {
          ...state.fbCampaignList,
          selectedData: action.selectedData,
        },
      };
    }
    case FETCH_FB_CAMPAIGN_STATUS: {
      return {
        ...state,
        fbCampaignStatus: {
          ...state.fbCampaignStatus,
          isFetching: action.isFetching,
          data: action.data,
        },
      };
    }
    case FETCH_FB_CAMPAIGN_STATUS_BY_DATE: {
      return {
        ...state,
        fbCampaignStatusDetails: {
          ...state.fbCampaignStatusDetails,
          isFetching: action.isFetching,
          data: action.data,
        },
      };
    }
    case UPDATE_META_DETAILS: {
      return {
        ...state,
        metaStatusDetails: {
          ...state.metaStatusDetails,
          accountStatus: action.accountStatus,
          lastTokenGenerate: action.lastTokenGenerate,
        },
      };
    }
    default:
      return state;
  }
};

export default managePageReducer;

import React from "react";
import PropTypes from "prop-types";
import ReactTooltip from "react-tooltip";
import { border, color, layout, position, space, system } from "styled-system";
import styled, { useTheme } from "styled-components";

import { ReactComponent as ActiveDot } from "konnekt/Icon/active-dot.svg";
import { ReactComponent as AutomateEasyLogo } from "konnekt/Icon/automate-logo.svg";
import { ReactComponent as UserIcon } from "konnekt/Icon/user-icon.svg";
import { ReactComponent as PencilIcon } from "./pencil-icon.svg";
import { ReactComponent as ContactIcon } from "./green-contact-icon.svg";
import { ReactComponent as FacebookIcon } from "./green-fb-icon.svg";
import { ReactComponent as KeyIcon } from "./green-key-icon.svg";
import { ReactComponent as TickIcon } from "./green-tick-icon.svg";
import { ReactComponent as WhatsappLogo } from "./whatsapp-icon.svg";
import { ReactComponent as AddMessage } from "./addMessage.svg";
import { ReactComponent as AgentAssign } from "./agent-assign.svg";
import { ReactComponent as Analytics } from "./analytics.svg";
import { ReactComponent as Announcement } from "./announcement.svg";
import { ReactComponent as ApiKey } from "konnekt/Icon/apiKey.svg";
import { ReactComponent as AppleIcon } from "./appleIcon.svg";
import { ReactComponent as AppliedFilter } from "./applied-filter.svg";
import { ReactComponent as ArrowBottom } from "./arrow-bottom.svg";
import { ReactComponent as Attachment } from "./attachment.svg";
import { ReactComponent as Audience } from "konnekt/Icon/audience.svg";
import { ReactComponent as AudioChat } from "./audioChat.svg";
import { ReactComponent as AudioPreview } from "./audioPreview.svg";
import { ReactComponent as Back } from "konnekt/Icon/back.svg";
import { ReactComponent as BackArrow } from "./backArrow.svg";
import { ReactComponent as Billing } from "konnekt/Icon/billing.svg";
import { ReactComponent as BoldIcon } from "./bold.svg";
import { ReactComponent as Bot } from "konnekt/Icon/bot.svg";
import { ReactComponent as Bulb } from "./bulb.svg";
import { ReactComponent as CSVFile } from "./csv-file.svg";
import { ReactComponent as Calendar } from "./calendar.svg";
import { ReactComponent as Call } from "konnekt/Icon/call.svg";
import { ReactComponent as CampaignBlack } from "konnekt/Icon/campaign-black.svg";
import { ReactComponent as CancelledIcon } from "./cancelled.svg";
import { ReactComponent as Canned } from "konnekt/Icon/canned.svg";
import { ReactComponent as Cart } from "konnekt/Icon/cart.svg";
import { ReactComponent as Chat } from "./chat.svg";
import { ReactComponent as CheckboxEmpty } from "konnekt/Icon/checkbox-empty.svg";
import { ReactComponent as CheckboxIntermediate } from "konnekt/Icon/checkbox_intermediate.svg";
import { ReactComponent as Circle } from "konnekt/Icon/circle.svg";
import { ReactComponent as CircleWithDot } from "konnekt/Icon/circleWithDot.svg";
import { ReactComponent as CloseIcon } from "./close.svg";
import { ReactComponent as CloseInCircleIcon } from "./closeInCircle.svg";
import { ReactComponent as Commerce } from "konnekt/Icon/commerce.svg";
import { ReactComponent as ConfirmationIcon } from "./confirmation.svg";
import { ReactComponent as ConvLabel } from "./conv-label.svg";
import { ReactComponent as CrossFilled } from "./cross-filled.svg";
import { ReactComponent as CurrencyRs } from "konnekt/Icon/currencyRs.svg";
import { ReactComponent as Delete } from "./delete.svg";
import { ReactComponent as DeleteVector } from "konnekt/Icon/deleteVector.svg";
import { ReactComponent as Diamond } from "konnekt/Icon/diamond.svg";
import { ReactComponent as DiamondWOBG } from "konnekt/Icon/diamondWOBG.svg";
import { ReactComponent as DocPreview } from "./docPreview.svg";
import { ReactComponent as Document } from "./document.svg";
import { ReactComponent as DoubleTick } from "./doubleTick.svg";
import { ReactComponent as Download } from "./download.svg";
import { ReactComponent as DownloadDoc } from "./downloadDoc.svg";
import { ReactComponent as DownloadFile } from "./downloadFile.svg";
import { ReactComponent as Duplicate } from "./duplicate.svg";
import { ReactComponent as Edit } from "./edit.svg";
import { ReactComponent as EditVector } from "konnekt/Icon/editVector.svg";
import { ReactComponent as EmojiIcon } from "./emoji.svg";
import { ReactComponent as Error } from "./error.svg";
import { ReactComponent as Events } from "./events.svg";
import { ReactComponent as Export } from "./export.svg";
import { ReactComponent as ExportV2 } from "./exportV2.svg";
import { ReactComponent as Eye } from "./eye.svg";
import { ReactComponent as Facebook } from "./facebook.svg";
import { ReactComponent as FilledAudience } from "konnekt/Icon/filledAudience.svg";
import { ReactComponent as FilledFacebook } from "./filledFacebook.svg";
import { ReactComponent as FilledFile } from "konnekt/Icon/filledFile.svg";
import { ReactComponent as FilledFlag } from "./filledFlag.svg";
import { ReactComponent as Filter } from "./filter.svg";
import { ReactComponent as Flag } from "./flag.svg";
import { ReactComponent as Flow } from "./flow.svg";
import { ReactComponent as Forward } from "konnekt/Icon/forward.svg";
import { ReactComponent as Global } from "konnekt/Icon/global.svg";
import { ReactComponent as GooglePLayIcon } from "./googlePlayIcon.svg";
import { ReactComponent as Help } from "./help.svg";
import { ReactComponent as HourGlass } from "konnekt/Icon/hourGlass.svg";
import { ReactComponent as Hours } from "./hours.svg";
import { ReactComponent as HoursFilled } from "./hours-filled.svg";
import { ReactComponent as InactiveDot } from "konnekt/Icon/inactive-dot.svg";
import { ReactComponent as Inbox } from "konnekt/Icon/inbox.svg";
import { ReactComponent as InboxSetting } from "./inbox-setting.svg";
import { ReactComponent as InboxTemplate } from "konnekt/Icon/inboxTemplate.svg";
import { ReactComponent as Incoming } from "./incoming.svg";
import { ReactComponent as Info } from "./info.svg";
import { ReactComponent as InfoOutlined } from "./infoOutlined.svg";
import { ReactComponent as Input } from "konnekt/Icon/input.svg";
import { ReactComponent as Interakt } from "./interakt.svg";
import { ReactComponent as ItalicIcon } from "./italic.svg";
import { ReactComponent as LinkBrokenIcon } from "./linkBroken.svg";
import { ReactComponent as ListBullet } from "konnekt/Icon/listBullet.svg";
import { ReactComponent as LiveChat } from "konnekt/Icon/liveChat.svg";
import { ReactComponent as Lock } from "./lock.svg";
import { ReactComponent as LoginBlocker } from "./loginBlocker.svg";
import { ReactComponent as Logo } from "konnekt/Icon/logo.svg";
import { ReactComponent as ManageProfile } from "konnekt/Icon/manageProfile.svg";
import { ReactComponent as MenuDot } from "konnekt/Icon/menuDot.svg";
import { ReactComponent as Message } from "./message.svg";
import { ReactComponent as MessageBG } from "./messageBG.svg";
import { ReactComponent as MessageFilled } from "./message-filled.svg";
import { ReactComponent as Microphone } from "./microphone.svg";
import { ReactComponent as MultiAssign } from "./multiAssign.svg";
import { ReactComponent as MultiCloseChat } from "./multiCloseChat.svg";
import { ReactComponent as MultiSelect } from "./multiSelect.svg";
import { ReactComponent as NewTab } from "./newTab.svg";
import { ReactComponent as Next } from "./next.svg";
import { ReactComponent as NoResult } from "konnekt/Icon/noResult.svg";
import { ReactComponent as Note } from "konnekt/Icon/note.svg";
import { ReactComponent as Notification } from "konnekt/Icon/notification.svg";
import { ReactComponent as Notifications } from "konnekt/Icon/notifications.svg";
import { ReactComponent as OrderDots } from "konnekt/Icon/orderDots.svg";
import { ReactComponent as Outgoing } from "./outgoing.svg";
import { ReactComponent as PauseIcon } from "./pause.svg";
import { ReactComponent as PlayIcon } from "./play.svg";
import { ReactComponent as PlusIcon } from "./plus-icon.svg";
import { ReactComponent as PlusIcons } from "konnekt/Icon/plusIcons.svg";
import { ReactComponent as Premium } from "./premium.svg";
import { ReactComponent as Prev } from "./prev.svg";
import { ReactComponent as Preview } from "konnekt/Icon/preview.svg";
import { ReactComponent as ProductPanel } from "konnekt/Icon/product.svg";
import { ReactComponent as ProfileIcon } from "./profile-circle.svg";
import { ReactComponent as Puser } from "./puser.svg";
import { ReactComponent as QuestionMark } from "./question-mark.svg";
import { ReactComponent as QuickReply } from "konnekt/Icon/quickReply.svg";
import { ReactComponent as RectangleIcon } from "./rectangle-mobile-head.svg";
import { ReactComponent as Refresh } from "konnekt/Icon/refresh.svg";
import { ReactComponent as RefreshLoading } from "konnekt/Icon/refresh-loading.svg";
import { ReactComponent as RemoveIcon } from "./remove.svg";
import { ReactComponent as ReplyBack } from "./replyBlack.svg";
import { ReactComponent as Restore } from "./restore.svg";
import { ReactComponent as Rotate } from "konnekt/Icon/rotate.svg";
import { ReactComponent as RotateRight } from "./rotate-right.svg";
import { ReactComponent as SearchBG } from "./searchBG.svg";
import { ReactComponent as SearchIcon } from "./search.svg";
import { ReactComponent as SendGreen } from "./sendGreen.svg";
import { ReactComponent as Settings } from "konnekt/Icon/settings.svg";
import { ReactComponent as Shipping } from "./shipping.svg";
import { ReactComponent as Shop } from "konnekt/Icon/shop.svg";
import { ReactComponent as Shopify } from "./shopify.svg";
import { ReactComponent as SignupWhatsapp } from "konnekt/Icon/signupWhatsapp.svg";
import { ReactComponent as SingleTick } from "./singleTick.svg";
import { ReactComponent as Square } from "konnekt/Icon/square.svg";
import { ReactComponent as SquareWithTick } from "konnekt/Icon/squareWithTick.svg";
import { ReactComponent as StrikethroughIcon } from "./strikethrough.svg";
import { ReactComponent as Success } from "konnekt/Icon/success.svg";
import { ReactComponent as SwitchOff } from "konnekt/Icon/switch-off.svg";
import { ReactComponent as SwitchOn } from "konnekt/Icon/switch-on.svg";
import { ReactComponent as Tag } from "konnekt/Icon/tag.svg";
import { ReactComponent as TagOutline } from "konnekt/Icon/tagOutline.svg";
import { ReactComponent as Tags } from "konnekt/Icon/tags.svg";
import { ReactComponent as Team } from "konnekt/Icon/team.svg";
import { ReactComponent as Template } from "konnekt/Icon/template.svg";
import { ReactComponent as Templates } from "konnekt/Icon/templates.svg";
import { ReactComponent as Tick } from "./tick.svg";
import { ReactComponent as Time } from "konnekt/Icon/time.svg";
import { ReactComponent as Tooltip } from "./tooltip.svg";
import { ReactComponent as TopRightArrow } from "./topRightArrow.svg";
import { ReactComponent as UnsupportedFile } from "konnekt/Icon/unsupportedFile.svg";
import { ReactComponent as Upload } from "./upload.svg";
import { ReactComponent as User } from "./user.svg";
import { ReactComponent as UserAttr } from "konnekt/Icon/userAttr.svg";
import { ReactComponent as Users } from "konnekt/Icon/users.svg";
import { ReactComponent as Video } from "./video.svg";
import { ReactComponent as VideoPreivew } from "./video-preview.svg";
import { ReactComponent as VisitLink } from "konnekt/Icon/visit-link.svg";
import { ReactComponent as Warning } from "./warning-icon.svg";
import { ReactComponent as WhatsappIcon } from "./whatsapp.svg";
import { ReactComponent as WhiteCircle } from "konnekt/Icon/whiteCircle.svg";
import { ReactComponent as WhiteCircleWithDot } from "konnekt/Icon/whiteCircleWithDot.svg";
import { ReactComponent as Widget } from "konnekt/Icon/widget.svg";
import { ReactComponent as SyncStatus } from "konnekt/Icon/syncStatus.svg";
import { ReactComponent as RedDelete } from "konnekt/Icon/redDelete.svg";
import { ReactComponent as Eyes } from "konnekt/Icon/eyes.svg";
import { ReactComponent as YellowStar } from "konnekt/Icon/yellowStar.svg";
import { ReactComponent as GreyFillArrow } from "konnekt/Icon/greyFillArrow.svg";
import { ReactComponent as Dashboard } from "konnekt/Icon/dashboard.svg";
import { ReactComponent as Live } from "konnekt/Icon/live.svg";
import { ReactComponent as History } from "konnekt/Icon/history.svg";
import { ReactComponent as Contact } from "konnekt/Icon/contact.svg";
import { ReactComponent as Campaigns } from "konnekt/Icon/campaigns.svg";
import { ReactComponent as Manage } from "konnekt/Icon/manage.svg";
import { ReactComponent as Bar } from "konnekt/Icon/bar.svg";
import { getThemeColor } from "util/styledComponent";
import CommerceV2 from "./commerceV2";
import { ReactComponent as DownArrow } from "konnekt/Icon/downArrow.svg";
import { ReactComponent as OpenBook } from "konnekt/Icon/openBook.svg";
import { ReactComponent as Sending } from "konnekt/Icon/sending.svg";
import { ReactComponent as NeedInfo } from "konnekt/Icon/needInfo.svg";
import { ReactComponent as Minus } from "konnekt/Icon/minus.svg";
import { ReactComponent as Book } from "konnekt/Icon/book.svg";
import { ReactComponent as FlashOn } from "konnekt/Icon/flash_on.svg";
import { ReactComponent as Home } from "konnekt/Icon/home.svg";
import { ReactComponent as Mail } from "konnekt/Icon/mail.svg";
import { ReactComponent as Location } from "konnekt/Icon/location.svg";
import { ReactComponent as GreenCall } from "konnekt/Icon/greenCall.svg";
import { ReactComponent as GreenMessage } from "konnekt/Icon/greenMessage.svg";
import { ReactComponent as GreenVideo } from "konnekt/Icon/greenVideo.svg";
import { ReactComponent as GrayGlobal } from "konnekt/Icon/grayGlobal.svg";
import { ReactComponent as Device } from "konnekt/Icon/device.svg";
import { ReactComponent as EyeCrossed } from "konnekt/Icon/eye-crossed.svg";
import { ReactComponent as GreenTick } from "konnekt/Icon/green-tick.svg";
import { ReactComponent as Link } from "konnekt/Icon/link.svg";
import { ReactComponent as Block } from "konnekt/Icon/block.svg";
import { ReactComponent as Ecommerce } from "konnekt/Icon/ecommerce.svg";
import { ReactComponent as AutoMobile } from "konnekt/Icon/automobile.svg";
import { ReactComponent as Banking } from "konnekt/Icon/banking.svg";
import { ReactComponent as Education } from "konnekt/Icon/education.svg";
import { ReactComponent as Health } from "konnekt/Icon/health.svg";
import { ReactComponent as NonProfit } from "konnekt/Icon/nonProfit.svg";
import { ReactComponent as Services } from "konnekt/Icon/services.svg";
import { ReactComponent as State } from "konnekt/Icon/state.svg";
import { ReactComponent as Webinar } from "konnekt/Icon/webniar.svg";
import { ReactComponent as Image } from "konnekt/Icon/image.svg";
import { ReactComponent as VideoIcon } from "konnekt/Icon/video1.svg";
import { ReactComponent as LeadGeneration } from "konnekt/Icon/lead-generation.svg";
import { ReactComponent as Clicked } from "konnekt/Icon/clicked.svg";
import { ReactComponent as RightArrow } from "konnekt/Icon/right-arrow.svg";
import { ReactComponent as Sync } from "konnekt/Icon/sync.svg";

// Always keep this list alphabetically sorted
export const iconMap = {
  activeDot: ActiveDot,
  automateEasyLogo: AutomateEasyLogo,
  agentAssign: AgentAssign,
  analytics: Analytics,
  announcement: Announcement,
  appliedFilter: AppliedFilter,
  arrowBottom: ArrowBottom,
  attachment: Attachment,
  audience: Audience,
  audioChat: AudioChat,
  audioPreview: AudioPreview,
  backArrow: BackArrow,
  bold: BoldIcon,
  bot: Bot,
  bulb: Bulb,
  calendar: Calendar,
  cancelled: CancelledIcon,
  chat: Chat,
  contactIcon: ContactIcon,
  device: Device,
  facebookIcon: FacebookIcon,
  whatsappLogo: WhatsappLogo,
  keyIcon: KeyIcon,
  tickIcon: TickIcon,
  close: CloseIcon,
  confirmation: ConfirmationIcon,
  convLabel: ConvLabel,
  commerce: Commerce,
  commerceV2: CommerceV2,
  crossFilled: CrossFilled,
  csvFile: CSVFile,
  delete: Delete,
  docPreview: DocPreview,
  document: Document,
  downloadDoc: DownloadDoc,
  duplicate: Duplicate,
  download: Download,
  downloadFile: DownloadFile,
  upload: Upload,
  userIcon: UserIcon,
  edit: Edit,
  emoji: EmojiIcon,
  error: Error,
  export: Export,
  exportV2: ExportV2,
  eye: Eye,
  facebook: Facebook,
  filledFacebook: FilledFacebook,
  filter: Filter,
  help: Help,
  hourGlass: HourGlass,
  hours: Hours,
  hoursFilled: HoursFilled,
  inactiveDot: InactiveDot,
  inbox: Inbox,
  inboxSetting: InboxSetting,
  incoming: Incoming,
  info: Info,
  infoOutlined: InfoOutlined,
  interakt: Interakt,
  italic: ItalicIcon,
  linkBroken: LinkBrokenIcon,
  loginBlocker: LoginBlocker,
  logo: Logo,
  message: Message,
  messageBG: MessageBG,
  messageFilled: MessageFilled,
  microphone: Microphone,
  multiSelect: MultiSelect,
  multiAssign: MultiAssign,
  multiCloseChat: MultiCloseChat,
  newTab: NewTab,
  next: Next,
  notifications: Notifications,
  outgoing: Outgoing,
  pause: PauseIcon,
  play: PlayIcon,
  plus: PlusIcon,
  premium: Premium,
  prev: Prev,
  profile: ProfileIcon,
  pencilIcon: PencilIcon,
  questionMark: QuestionMark,
  rectangle: RectangleIcon,
  replyBack: ReplyBack,
  rotate: Rotate,
  rotateRight: RotateRight,
  search: SearchIcon,
  searchBG: SearchBG,
  shopify: Shopify,
  success: Success,
  shipping: Shipping,
  video: Video,
  videoPreview: VideoPreivew,
  warning: Warning,
  unsupportedFile: UnsupportedFile,
  time: Time,
  remove: RemoveIcon,
  settings: Settings,
  signupWhatsapp: SignupWhatsapp,
  square: Square,
  squareWithTick: SquareWithTick,
  preview: Preview,
  widget: Widget,
  strikethrough: StrikethroughIcon,
  templates: Templates,
  tick: Tick,
  tooltip: Tooltip,
  users: Users,
  whatsapp: WhatsappIcon,
  tag: Tag,
  tagOutline: TagOutline,
  topRightArrow: TopRightArrow,
  filledAudience: FilledAudience,
  closeInCircle: CloseInCircleIcon,
  productPanel: ProductPanel,
  shop: Shop,
  quickReply: QuickReply,
  inboxTemplate: InboxTemplate,
  listBullet: ListBullet,
  cart: Cart,
  currencyRs: CurrencyRs,
  filledFile: FilledFile,
  diamond: Diamond,
  switchOn: SwitchOn,
  switchOff: SwitchOff,
  call: Call,
  visitLink: VisitLink,
  checkboxEmpty: CheckboxEmpty,
  checkboxIntermediate: CheckboxIntermediate,
  diamondWOBG: DiamondWOBG,
  back: Back,
  global: Global,
  refreshLoading: RefreshLoading,
  refresh: Refresh,
  input: Input,
  noResult: NoResult,
  circle: Circle,
  circleWithDot: CircleWithDot,
  whiteCircle: WhiteCircle,
  whiteCircleWithDot: WhiteCircleWithDot,
  campaignBlack: CampaignBlack,
  forward: Forward,
  menuDot: MenuDot,
  note: Note,
  orderDots: OrderDots,
  user: User,
  doubleTick: DoubleTick,
  singleTick: SingleTick,
  sendGreen: SendGreen,
  flow: Flow,
  events: Events,
  addMessage: AddMessage,
  restore: Restore,
  appleIcon: AppleIcon,
  googlePlayIcon: GooglePLayIcon,
  flag: Flag,
  filledFlag: FilledFlag,
  puser: Puser,
  lock: Lock,
  userAttr: UserAttr,
  template: Template,
  canned: Canned,
  team: Team,
  tags: Tags,
  analyticsIcon: Analytics,
  apiKey: ApiKey,
  billing: Billing,
  notification: Notification,
  liveChat: LiveChat,
  manageProfile: ManageProfile,
  plusIcons: PlusIcons,
  editVector: EditVector,
  deleteVector: DeleteVector,
  syncStatus: SyncStatus,
  redDelete: RedDelete,
  eyes: Eyes,
  yellowStar: YellowStar,
  greyFillArrow: GreyFillArrow,
  dashboard: Dashboard,
  live: Live,
  history: History,
  contact: Contact,
  campaigns: Campaigns,
  manage: Manage,
  bar: Bar,
  downArrow: DownArrow,
  openBook: OpenBook,
  sending: Sending,
  needInfo: NeedInfo,
  minus: Minus,
  book: Book,
  flashOn: FlashOn,
  home: Home,
  location: Location,
  mail: Mail,
  greenMessage: GreenMessage,
  greenCall: GreenCall,
  greenVideo: GreenVideo,
  grayGlobal: GrayGlobal,
  eyeCrossed: EyeCrossed,
  greenTick: GreenTick,
  link: Link,
  block: Block,
  ecommerce: Ecommerce,
  automobile: AutoMobile,
  banking: Banking,
  education: Education,
  health: Health,
  nonProfit: NonProfit,
  services: Services,
  state: State,
  webinar: Webinar,
  image: Image,
  videoIcon: VideoIcon,
  leadGeneration: LeadGeneration,
  clicked: Clicked,
  rightArrow: RightArrow,
  sync: Sync,
};

const iconSizeMap = {
  xl: "76px",
  "2large": "60px",
  large: "42px",
  normal: "36px",
  small: "24px",
  xs: "19px",
  "2xs": "16px",
  tiny: "13px",
  "2tiny": "10px",
};

const StyledIcon = styled.div`
  height: ${(props) => iconSizeMap[props.iconSize]};
  width: ${(props) => iconSizeMap[props.iconSize]};
  cursor: ${(props) => (props.onClick ? "pointer" : null)};
  display: flex;

  ${space}
  ${position}
  ${layout}
  ${color}
  ${border}

  ${system({
    transform: {
      property: "transform",
    },
    cursor: {
      property: "cursor",
    },
  })}
`;

// IconSize is predefined sizes

const IconWrapper = ({ iconName, onClick, iconSize, noWrapper, stroke, ...rest }) => {
  const theme = useTheme();
  const IconSVG = iconMap[iconName] || QuestionMark;
  const iconProps = {};
  if (rest.color) {
    iconProps.fill = getThemeColor(theme, rest.color);
  }
  if (iconSize || rest.height) {
    iconProps.height = iconSizeMap[iconSize] || rest.height;
  }
  if (iconSize || rest.width) {
    iconProps.width = iconSizeMap[iconSize] || rest.width;
  }

  if (stroke) {
    iconProps.stroke = stroke;
  }

  if (rest.iconClass) {
    iconProps.className = rest.iconClass;
  }

  const renderReactToolTip = () => {
    if (rest["data-for"] && rest["data-tip"]) {
      return (
        <ReactTooltip
          className={`tooltip special-tooltip  ${rest.toolTipClass || "tooltip__image-preview"}`}
          multiline
          place={rest.place || "top"}
          effect="solid"
          id={rest["data-for"]}
        >
          {rest["data-tip"]}
        </ReactTooltip>
      );
    }
  };

  if (!noWrapper) {
    return (
      <>
        {renderReactToolTip()}
        <StyledIcon {...rest} iconSize={iconSize} onClick={onClick}>
          <IconSVG {...iconProps} />
        </StyledIcon>
      </>
    );
  }
  return (
    <>
      {renderReactToolTip()}
      <IconSVG {...rest} {...iconProps} onClick={onClick} />
    </>
  );
};

IconWrapper.propTypes = {
  onClick: PropTypes.func,
  iconName: PropTypes.string.isRequired,
  iconSize: PropTypes.oneOf(["xl", "2large", "large", "normal", "small", "xs", "2xs", "tiny", "2tiny"]),
  noWrapper: PropTypes.bool,
  stroke: PropTypes.string,
};

IconWrapper.defaultProps = {
  noWrapper: false,
};

export default IconWrapper;
